import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { dialogTransition } from '../../utils/transitions';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  useTheme,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

export function CustomDialogBox({
  open,
  closeDialog,
  handleSubmit,
  isSubmitting,
  buttonText,
  messageBody,
  boldMessageBody,
  hasIcon = false,
  icon,
  bodyComponent,
  showCancelButton = true,
}: {
  open: boolean;
  closeDialog: () => void;
  handleSubmit: () => void;
  isSubmitting: boolean;
  buttonText: string;
  messageBody: string;
  hasIcon?: boolean;
  icon?: React.ReactNode;
  boldMessageBody?: string;
  bodyComponent?: React.ReactNode;
  showCancelButton?: boolean;
}) {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      TransitionComponent={dialogTransition}
      scroll='body'
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 400,
        }}
      >
        <Box
          sx={{
            textAlign: 'right',
          }}
        >
          {!showCancelButton ? (
            <>
              <IconButton onClick={closeDialog}>
                <ClearOutlinedIcon />
              </IconButton>
            </>
          ) : undefined}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {hasIcon
            ? icon ?? (
                <WarningAmberRoundedIcon
                  sx={{
                    fontSize: '64px',
                    color: theme.palette.warning.dark,
                    mb: 1,
                  }}
                />
              )
            : undefined}
        </Box>
        <p>
          {messageBody}
          <strong>{boldMessageBody}</strong>
        </p>
        {bodyComponent ? bodyComponent : undefined}
      </DialogContent>
      <DialogActions>
        {showCancelButton ? (
          <Button onClick={closeDialog}>Cancel</Button>
        ) : undefined}
        <LoadingButton
          loading={isSubmitting}
          onClick={handleSubmit}
          variant='contained'
        >
          {buttonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default CustomDialogBox;
