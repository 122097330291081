import { InputAdornment } from '@mui/material';
import {
  Schema,
  validatorTypes,
  customComponentTypes,
} from '../../../reusable-components/data-driven-forms';

const schema = {
  description: 'Login',
  fields: [
    {
      label: 'Phone Number',
      name: 'phone',
      type: 'number',
      component: customComponentTypes.TEXT_FIELD,
      autoComplete: 'on',
      InputProps: {
        startAdornment: <InputAdornment position='start'>+91</InputAdornment>,
      },
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
        {
          type: validatorTypes.EXACT_LENGTH,
          threshold: 10,
        },
      ],
    },
    {
      label: 'Password',
      name: 'password',
      type: 'password',
      autoComplete: 'new-password',
      component: customComponentTypes.TEXT_FIELD,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
  ],
} as const;

export type LoginFormValues = {
  [K in (typeof schema.fields)[number]['name']]: any;
};

export default schema as any as Schema;
