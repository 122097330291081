import { InputAdornment } from '@mui/material';
import {
  Schema,
  validatorTypes,
  customComponentTypes,
} from '../../../reusable-components/data-driven-forms';

const forgotPasswordSchema = {
  description: 'Forgot Password',
  fields: [
    {
      label: 'Phone Number',
      name: 'phone_number',
      component: customComponentTypes.TEXT_FIELD,
      InputProps: {
        startAdornment: <InputAdornment position='start'>+91</InputAdornment>,
      },
      type: 'number',
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
        {
          type: validatorTypes.EXACT_LENGTH,
          threshold: 10,
        },
      ],
    },
    {
      label: 'New Password',
      name: 'new_password',
      type: 'password',
      autoComplete: 'new-password',
      component: customComponentTypes.TEXT_FIELD,
      isRequired: true,
      condition: {
        when: 'phone_number',
        isNotEmpty: true,
      },
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
        {
          type: validatorTypes.MIN_LENGTH,
          threshold: 6,
        },
        {
          type: validatorTypes.MAX_LENGTH,
          threshold: 20,
        },
      ],
    },
    {
      label: 'Confirm Password',
      name: 'confirm_password',
      type: 'password',
      component: customComponentTypes.TEXT_FIELD,
      condition: {
        when: 'phone_number',
        isNotEmpty: true,
      },
      resolveProps: (props: any, meta: any, formOptions: any) => {
        const values = formOptions.getState().values;
        if (
          values.new_password !== undefined &&
          meta.input?.value !== undefined &&
          values.new_password !== meta.input?.value
        ) {
          return {
            error: true,
            helperText: 'Password does not match',
          };
        }
      },
      condiition: {
        when: 'phone',
        isEmpty: false,
      },
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
  ],
} as const;

export type ForgotPasswordFormValues = {
  [K in (typeof forgotPasswordSchema.fields)[number]['name']]: any;
};

export default forgotPasswordSchema as any as Schema;
