import axios from 'axios';
import * as environment from '../../environment';
import authInterceptor from './authInterceptor';
import responseInterceptor from './responseInterceptor';
import errorInterceptor from './errorInterceptor';

export const logisticsApiClient = axios.create({
  baseURL: environment.logisticsBaseUrl,
});

logisticsApiClient.interceptors.request.use(authInterceptor);
logisticsApiClient.interceptors.response.use(responseInterceptor, errorInterceptor);
