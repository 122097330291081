import { store } from '../../redux/store';
import { logoutThunkAction } from '../../redux/login/thunk-actions';

const errorInterceptor = async (error: any): Promise<any> => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  const statusCode = error.response.status;
  const isProfileSuspended =
    error.response.data['message'] === 'Suspended Profile';
  if (statusCode === 401 || isProfileSuspended) {
    store.dispatch(logoutThunkAction());
  }
  return Promise.reject(error);
};

export default errorInterceptor;
