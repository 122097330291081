import { AxiosResponse } from 'axios';
import { authTokenUrl, updatePasswordUrl } from './apiUrls/commerceApiUrls';
import * as environment from '../../environment';
import { GetOtpResponse, UserData } from '../model/auth/auth';
import { commerceApiClient } from './commerceApiClient';

export const getOtp = async (phone: string) => {
  const axiosResponse: AxiosResponse<GetOtpResponse> = await commerceApiClient.get(
    authTokenUrl,
    {
      params: {
        phone: phone,
        third_party_id: environment.thirdPartyId,
      },
    }
  );
  return axiosResponse.data;
};

export const verifyOtp = async (token: string, phone: string) => {
  const axiosResponse: AxiosResponse<UserData> = await commerceApiClient.post(
    authTokenUrl,
    {
      phone: phone,
      token: token,
      third_party_id: environment.thirdPartyId,
    }
  );
  return axiosResponse.data;
};

export const updatePassword = async (password: string, userToken: string) => {
  const axiosResponse: AxiosResponse<any> = await commerceApiClient.patch(
    updatePasswordUrl,
    {
      password: password,
    },
    {
      headers: {
        Authorization: `JWT ${userToken}`,
      },
    }
  );
  return axiosResponse.data;
};
