import axios from 'axios';
import * as environment from '../../environment';
import authInterceptor from './authInterceptor';
import errorInterceptor from './errorInterceptor';
import responseInterceptor from './responseInterceptor';

export const commerceApiClient = axios.create({
  baseURL: environment.commerceApiBaseUrl,
});

commerceApiClient.interceptors.request.use(authInterceptor);
commerceApiClient.interceptors.response.use(responseInterceptor, errorInterceptor);
