export const onboardUserUrl = `/api/v1/auth/profile/onboard`;
// Media
export const uploadPhotoUrl = '/api/v1/media/photo/';

//authUrls
export const authTokenUrl = '/api/v1/auth/token';
export const refreshTokenUrl = '/api/v1/auth/refresh/token';
export const userRolesUrl = '/api/v1/auth/profiles';
export const userUrl = '/api/v1/auth/user';
export const updatePasswordUrl = 'api/v1/auth/password/';

// Geography
export const getReporteesUrl = (managerId: string) =>
  `/api/v1/employee-management/manager/${managerId}/reportees`;

export const linkReporteeUrl = () =>
  `/api/v1/employee-management/manager/reportees`;

export const getCheckinsUrl = () => `/api/v1/geotracking/checkin`;