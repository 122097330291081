import { useAppDispatch } from '../../../redux/hooks';
import { Box, Button, Container } from '@mui/material';
import { FormRenderer } from '@data-driven-forms/react-form-renderer';
import schema, {
  ForgotPasswordFormValues,
} from './forgot-password-form-schema';
import {
  CustomFormTemplate,
  customComponentMapper,
} from '../../../reusable-components/data-driven-forms';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined';
import useDialogBox from '../../../utils/dialogbox-hook';
import { useState } from 'react';
import OtpInput from 'react-otp-input';
import { SnakbarUtils } from '../../../utils/snakbar-util';
import CustomDialogBox from '../../../reusable-components/CustomDialogBox';
import forgotPasswordSchema from './forgot-password-form-schema';
import {
  getOtpAction,
  verifyOtpAndUpdatePasswordAction,
} from '../../../redux/login/thunk-actions';

function ForgotPasswordPage() {
  const location = useLocation();
  const registeredNumber = location.state?.phone ?? '';
  const dialogBox = useDialogBox();
  const [otp, setOtp] = useState('');
  const [phone, setPhone] = useState(registeredNumber);
  const [newPassword, setNewPassword] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleResetPassword = async (formValues: ForgotPasswordFormValues) => {
    if (formValues.new_password === formValues.confirm_password) {
      const otpResponse: string | undefined = await dispatch(
        getOtpAction(formValues.phone_number ?? '')
      );
      if (otpResponse) {
        dialogBox.openDialog();
        setPhone(formValues.phone_number);
        setNewPassword(formValues.confirm_password);
        SnakbarUtils.showInfoMessage(`OTP: ${otpResponse}`);
      }
    }
  };
  const handleChangeOtp = async () => {
    if (otp.length === 6) {
      dialogBox.setIsSubmitting(true);
      const response: boolean = await dispatch(
        verifyOtpAndUpdatePasswordAction(phone ?? '', otp, newPassword)
      );
      if (response) {
        dialogBox.closeDialog();
        navigate(-1);
      }
    }
    dialogBox.setIsSubmitting(false);
  };

  return (
    <Box sx={{ width: '100%', height: '600px', mt: '100px' }}>
      <Container maxWidth='sm'>
        <Button
          variant='text'
          startIcon={<ArrowBackOutlined />}
          onClick={() => {
            navigate(-1);
          }}
        >
          Back to Login
        </Button>
        <Box height='20px' />
        <FormRenderer
          FormTemplate={CustomFormTemplate}
          componentMapper={customComponentMapper}
          schema={forgotPasswordSchema}
          onSubmit={handleResetPassword}
          initialValues={{
            submit_button_text: 'Reset Password',
            phone_number: phone,
          }}
        />
        <CustomDialogBox
          messageBody={`Enter OTP sent via SMS to phone number `}
          boldMessageBody={`${phone}`}
          open={dialogBox.open}
          closeDialog={() => {
            dialogBox.closeDialog();
            setOtp('');
          }}
          handleSubmit={handleChangeOtp}
          isSubmitting={dialogBox.isSubmitting}
          buttonText={'Submit'}
          bodyComponent={
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              shouldAutoFocus={true}
              containerStyle={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: '12px',
              }}
              inputStyle={{
                display: 'flex',
                width: '28px',
                height: '28px',
                borderRadius: '8px',
              }}
              inputType='tel'
              renderInput={(props) => <input {...props} />}
            />
          }
        />
      </Container>
    </Box>
  );
}

export default ForgotPasswordPage;
