import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import FormSpy from '@data-driven-forms/react-form-renderer/form-spy';
import Button from '@mui/material/Button';
import { Box, Grid, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const CustomFormTemplate = ({ formFields, schema }: any) => {
  const { handleSubmit, onReset, onCancel, getState } = useFormApi();
  const { submitting, valid, pristine } = getState();
  const classes = {
    buttonGroup: `CustomFormTemplate-buttonGroup`,
  };
  
  let button_text = getState().initialValues?.submit_button_text ?? 'Submit';
  let button_icon =
    getState().initialValues?.submit_button_prefix_icon ?? undefined;

  let cancel_button_text =
    getState().initialValues?.cancel_button_text ?? 'Cancel';
  let cancel_button_style =
    getState().initialValues?.cancel_button_style ?? undefined;
    
  const StyledButtonGroup = styled(Grid)(() => ({
    [`& .${classes.buttonGroup}`]: {
      marginTop: '16px',
      display: 'flex',
      justifyContent: 'flex-end',
      '&>button:not(last-child)': {
        marginLeft: 8,
      },
    },
  }));
  return (
    <form onSubmit={handleSubmit}>
      {schema.title}
      <Grid container rowSpacing={2} item xs={12}>
        {formFields}
      </Grid>
      <FormSpy>
        {() => (
          <StyledButtonGroup item xs={12}>
            <div className={classes.buttonGroup}>
            {onCancel ? (
                <Button
                  variant={
                    cancel_button_style ? cancel_button_style : 'outlined'
                  }
                  onClick={onCancel}
                  sx={{ minWidth: '85px' }}
                >
                  {cancel_button_text}
                </Button>
              ) : undefined}
              <Box width='12px' />
              <LoadingButton
                loading={submitting}
                type="submit"
                color="primary"
                variant="contained"
                disabled={Object.keys(getState().values).length === 0}
                sx={{ minWidth: '85px' }}
              >
                { button_text } { button_icon }
              </LoadingButton>
            </div>
          </StyledButtonGroup>
        )}
      </FormSpy>
    </form>
  );
};


export default CustomFormTemplate;