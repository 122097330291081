import { useAppDispatch } from '../../../redux/hooks';
import { Box, Container } from '@mui/material';
import { FormRenderer } from '@data-driven-forms/react-form-renderer';
import { loginFromApiThunkAction } from '../../../redux/login/thunk-actions';
import schema, { LoginFormValues } from './login-form-schema';
import {
  CustomFormTemplate,
  customComponentMapper,
} from '../../../reusable-components/data-driven-forms';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (formValues: LoginFormValues) => {
    await dispatch(
      loginFromApiThunkAction(formValues.phone, formValues.password)
    );
  };
  
  const handleForgotPassword = async (formValues: LoginFormValues) => {
    navigate(`./forgot-password`, {
      state: {
        phone: formValues.phone,
      },
    });
  };


  return (
    <Box sx={{ width: '100%', height: '600px', mt: '100px' }}>
      <Container maxWidth="sm">
        <FormRenderer
          FormTemplate={CustomFormTemplate}
          componentMapper={customComponentMapper}
          schema={schema}
          onSubmit={handleSubmit}
          onCancel={handleForgotPassword}
          initialValues={{
            submit_button_text: 'LogIn',
            cancel_button_text: 'Forgot Password?',
            cancel_button_style: 'text',
          }}
        />
      </Container>
    </Box>
  );
}

export default LoginPage;
